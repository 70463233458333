import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {BehaviorSubject, Observable} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {Role, User, UserListItem} from './types'
import {ConfigService} from './config.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * Consumers should listen to this to get the latest user and roles list.
   */
  public users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([{} as any])
  public roles$: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([{} as any])

  /**
   * Holds the userList and roleList to update users and roles BehaviorSubject
   *
   * @private
   */
  private userList: User[] = []

  /**
   *
   * @param configService
   * @param httpClient
   */
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
  ) {
  }

  public getUsers(update?: boolean): Observable<User[]> {
    let url = `${environment.authServiceUrl}/users`
    if (update === true) {
      url += `?bust=${new Date().getTime()}`
    }
    return this.httpClient.get<User[]>(url)
      .pipe(
        map((users: User[]) => {
          this.userList = users
          this.users$.next(this.userList)
          return users
        })
      )
  }

  /**
   * Randomly update or create users.
   */
  public updateUser(user: User): Observable<User[]> {
    const url = `${environment.authServiceUrl}/users`
    return this.httpClient.put<User>(url, user)
      .pipe(
        switchMap(() => this.getUsers())
      )
  }

  public userExist(sub: string): User | undefined {
    return this.users$.value.find((user: User) => user.sub === sub)
  }

  /**
   * Takes a user and filters out its roles
   */
  public filterUserRoles(user: UserListItem | any, roleList: Role[]): Role[] {
    return roleList.filter((r: Role) => user.roles.indexOf(r.key) !== -1)
  }

  public getUser(sId: string): User {
    return this.users$.value.find((user: User) => user.sId === sId) || {} as any
  }

  public deleteUser(user: User): Observable<any> {
    const url = `${environment.authServiceUrl}/users/${user.itemId}`
    return this.httpClient.delete<any>(url, {body: user})
  }

  /**
   * get all roles from database
   */
  public getAllRoles(): Observable<Role[]> {
    const url = `${environment.authServiceUrl}/roles`

    return this.httpClient.get<Role[]>(url)
      .pipe(
        map((roles: Role[]) => {
          this.roles$.next(roles)
          return roles
        })
      )
  }

  /**
   * Save a role to database
   */
  public updateRole(role: Role): Observable<Role[]> {
    const url = `${environment.authServiceUrl}/roles`
    return this.httpClient.put<Role>(url, role)
      .pipe(
        switchMap(() => this.getAllRoles())
      )
  }

  /**
   * Delete a role from database
   */
  public deleteRole(role: Role): Observable<void> {
    const url = `${environment.authServiceUrl}/roles/${role.itemId}`
    return this.httpClient.delete<void>(url, {body: role})
  }

  /**
   * The application configuration from the backend.
   */
  public getOffices(): Observable<string[]> {
    const url = `${environment.authServiceUrl}/data/offices`
    return this.httpClient.get<string[]>(url)
  }
}
